.memberPay {
  > .title {
    height: 5.5rem;
    line-height: 5.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    border-bottom: 0.1rem solid rgba(51, 51, 51, 0.08);
    justify-content: flex-start;
    padding: 0 2rem;
  }
  > .content {
    // padding: 0 2rem 2rem;
    .title {
      font-size: 1.6rem;
      font-weight: 600;
      color: #333333;
      padding: 2rem 2rem 3rem;
      .icon {
        font-size: 1.4rem;
        color: var(--bg-color);
        margin-right: 0.4rem;
      }
    }

    .memberPhone {
      align-items: center;
      height: 8rem;
      .icona-shouyintaisousuo:before {
        margin: 0 0.5rem 0 1rem;
        color: #037aee;
        opacity: 0.5;
        font-size: 1.6rem;
      }
      .inputPhone {
        font-size: 1.4rem;
        font-weight: 400;
        color: #333333;
      }
      .semi-input-wrapper {
        margin: 0 2rem 0 2rem;
        flex: 1;
        border-radius: 0.4rem;
        border: 0.1rem solid #a8bcd0;
        color: var(--bg-color);
        background: #ffffff;
        input {
          height: 4rem;
          font-size: 1.4rem;
        }
      }

      .search {
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
        color: var(--bg-color);
        font-size: 1.4rem;
      }
    }
    .actualAmount {
      font-size: 1.6rem;
      font-weight: 600;
      color: #333333;
      height: 2.2rem;
      margin-top: 1.5rem;
      > span {
        float: right;
        span {
          color: #ff3200;
        }
      }
    }

    .payMethod {
      margin-top: 2.5rem;
      justify-content: flex-end;
      .type {
        font-size: 1.4rem;
      }
      .balanceNotEnough {
        background: rgba(51, 51, 51, 0.2);
        color: #fff;
      }
      .balancePay {
        display: flex;
        width: 13rem;
        height: 3.5rem;
        background: var(--bg-color);
        border-radius: 0.4rem;
        color: #fff;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon {
          float: left;
          font-size: 1.6rem;
          color: #fff;
          margin-right: 0.4rem;
        }

        margin-left: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
