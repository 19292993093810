.basiclayout {
  background-color: var(--bg-color);
  height: 7rem;
  color: white;
  padding: 2rem;
  .shop-title {
    font-size: 1.8rem !important;
  }
  .logo {
    font-size: 1.8rem;
    white-space: nowrap;
    .img-logo {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.4rem;
      background-color: #fff;
      margin-right: 0.5rem;
      img {
        width: 2rem;
        height: 2.5rem;
      }
    }
    .info {
      white-space: nowrap;
    }
  }
}
