.distributionInfo {
  .mustInput {
    color: #f50000;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 400;
    color: #333333;
    margin: 1rem 0 0.5rem;
  }
  .disAddress {
    .dis-addressAll {
      width: 100%;
      .dis-address {
        width: 35%;
        height: 4rem;
        margin-right: 1rem;
        border-radius: 0.4rem;
        .ant-select {
          height: 4rem;
          border: 0.1rem solid #d9d9d9;
          border-radius: 0.4rem;
          width: 100%;
          display: flex;
          align-items: center;
          .ant-select-selector {
            span {
              font-size: 1.4rem;
              color: #333333;
            }
          }
          input {
            width: 100%;
            font-size: 1.4rem;
            color: #333333;
            display: flex;
            align-items: center;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .dis-time {
    width: 100%;
    // position: relative;
    .DisTime {
      width: 100%;
      position: relative;
      .iconshouyintai-rili1:before {
        color: #037aee;
      }
      > span {
        font-size: 1.4rem;
        position: absolute;
        z-index: 1;
        right: 1rem;
        top: 50%;
        transform: translate(0, -50%);
      }
      .semi-datepicker {
        width: 100%;
        height: 4rem;
        .semi-input-wrapper {
          height: 4rem;
          line-height: 4rem;
          border-radius: 0.4rem;
          border: 0.1rem solid #ccc;
          input {
            height: 4rem;
            font-size: 1.4rem;
            color: #333333;
          }
        }

        .semi-input-wrapper-default {
          height: unset;
          line-height: unset;
        }
        .semi-input-wrapper-focus {
          border: 0.1rem solid var(--bg-color);
        }

        div {
          background: #fff;
          height: 100%;
        }
        .semi-input-suffix {
          span {
            color: #fff;
          }
        }
        .semi-input-clearbtn {
          margin-right: 2rem;
        }
      }
    }
  }
  .customerInfo {
    width: 100%;
    .customer-name {
      width: 50%;
      margin-right: 1rem;
    }
    .customer-phone {
      width: 50%;
    }
    .semi-input-wrapper {
      background: #fff;
      border: 0.1rem solid #d9d9d9;
      border-radius: 0.4rem;
      input {
        font-size: 1.4rem;
        width: 100%;
        height: 4rem;

        color: #333333;
      }
    }
    .semi-input-wrapper-default {
      height: unset;
      line-height: unset;
    }
    .semi-input-wrapper-focus {
      border: 0.1rem solid var(--bg-color);
    }
  }
  .address-detail,
  .goods-remark,
  .dis-remark {
    .semi-input-wrapper {
      background: #fff;
      border: 0.1rem solid #d9d9d9;
      border-radius: 0.4rem;
      input {
        height: 4rem;
        color: #333;
        font-size: 1.4rem;
      }
    }
    .semi-input-wrapper-default {
      height: unset;
      line-height: unset;
    }
    .semi-input-wrapper-focus {
      border: 0.1rem solid var(--bg-color);
    }
  }
  .remarks {
    width: 100%;
    margin-bottom: 2rem;
    .goods-remark {
      width: 50%;
      margin-right: 1rem;
    }
    .dis-remark {
      width: 50%;
    }
  }
}
