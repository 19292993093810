.memberAdd {
  > .title {
    height: 5.5rem;
    line-height: 5.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    border-bottom: 0.1rem solid rgba(51, 51, 51, 0.08);
    padding: 0 2rem;
  }
  .content {
    > div {
      padding: 0 2rem;
      .title {
        font-size: 1.4rem;
        font-weight: 600;
        color: #333333;
      }

      .semi-input-wrapper {
        margin-top: 2rem;
        background: #fff;
        border-radius: 0.4rem;
        border: 0.1rem solid #a8bcd0;
        input {
          height: 4rem;
          width: 100%;
          font-size: 1.4rem;
          color: #333333;
        }
      }
      .semi-input-wrapper-default {
        height: unset;
        line-height: unset;
      }
      .semi-input-wrapper-focus {
        border: 0.1rem solid var(--bg-color);
      }
    }
    .memberAddInfo {
      margin-top: 1.5rem;
    }
    .memberType {
      margin: 2rem 0;
      .title {
        margin-bottom: 1.5rem;
      }
      .ant-select {
        font-size: 1.4rem;
        border: 0.1rem solid #a8bcd0;
        border-radius: 0.4rem;
        width: 100%;
        .ant-select-selector {
          width: 100%;
          height: 4rem;
          font-size: 1.4rem;
          display: flex;
          align-items: center;
        }
      }
    }
    .add-member-btn {
      justify-content: flex-end;
      padding-bottom: 2rem;
      button {
        height: 3.5rem;
        line-height: 3.5rem;
        border-radius: 0.4rem;
        background: var(--bg-color);
        color: #fff;
        &:first-child {
          margin-right: 1rem;
          background: #fff;
          color: var(--bg-color);
          border-color: var(--bg-color);
        }
        span {
          display: block;
          height: 100%;
          line-height: 3.5rem;
          font-size: 1.4rem;
        }
      }
    }
  }
}
