.giftCartPay {
  .actualAmount {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333333;
    height: 2.2rem;
    margin-top: 1.5rem;
    > span {
      float: right;
      span {
        color: #ff3200;
      }
    }
  }

  .payMethod {
    margin-top: 2.5rem;
    justify-content: end;
    .balanceNotEnough {
      background: rgba(51, 51, 51, 0.2);
      color: #fff;
    }
    .type {
      font-size: 1.4rem;
    }
    > div {
      display: flex;
      width: 13rem;
      height: 3.5rem;
      background: var(--bg-color);
      border-radius: 0.4rem;
      color: #fff;
      justify-content: center;
      align-items: center;
      .icon {
        float: left;
        font-size: 1.6rem;
        color: #fff;
        margin-right: 0.4rem;
      }

      margin-left: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .shouldPay {
    font-weight: 600;
    color: #333333;
    font-size: 1.6rem;
    padding: 1.5rem 2rem 0;
    justify-content: end;
    span {
      margin-left: 1.5rem;
      font-size: 1.6rem;
      color: #ff3200;
    }
  }
}

.memberPay {
  padding-bottom: 2rem;
}
