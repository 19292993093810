.OrderDetail {
  height: 100%;
  overflow: hidden;
  header {
    height: 8rem;
    .left-box {
      height: 100%;
      padding: 1.5rem;
      .header-title {
        font-size: 1.8rem;
        font-weight: 500;
        .purple {
          color: #333;
          white-space: nowrap;
          font-weight: 600;
        }
      }
    }
    .right-box {
      height: 100%;
      margin: 1rem;
      overflow-x: scroll;
      .one-box {
        white-space: nowrap;
        color: #333333;
        margin-right: 1rem;
        .type-box {
          display: flex;
          justify-content: flex-end;
          font-size: 1.8rem;
        }
        .time-box {
          display: flex;
          justify-content: flex-end;
          font-size: 1.4rem;
        }
      }
      .two-box {
        white-space: nowrap;
        .type-box {
          width: 8rem;
          height: 4.5rem;
          color: white;
          border-radius: 1rem 0 1rem 0;
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }
  }
  .content-box {
    .detail-box {
      background-color: #f4f6f8;
    }
  }
  footer {
    background: #999;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
