.orderdetail {
  background: #fff;

  .distribute-info {
    margin-bottom: 1rem;

    .title {
      font-weight: 600;
      font-size: 1.6rem;

      .vertical-line {
        border-left: 0.4rem solid var(--bg-color);
        height: 1.5rem;
      }

      .title-text {
        padding: 1rem;
      }

      .item-box {
        padding: 1rem 1.5rem;
        width: 100%;
        font-size: 1.6rem;
        background: #fff;
      }
    }

    .item-box {
      padding: 1rem 1.5rem;
      width: 100%;
      font-size: 1.6rem;
      background: #fff;
    }
  }

  .detail-info {
    margin-bottom: 1rem;

    .title {
      font-weight: 600;
      font-size: 1.6rem;

      .vertical-line {
        border-left: 0.4rem solid var(--bg-color);
        height: 1.5rem;
      }

      .title-text {
        padding: 1rem;
      }

      .order-detail-wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .refund {
          border-radius: 20px;
          border: 1px solid #FF3200;
          font-size: 14px;
          font-weight: 600;
          color: #FF3200;
          line-height: 20px;
          padding: .2rem 1rem;
          cursor: pointer;
        }
      }
    }

    .item-box {
      padding: 1rem 1.5rem;
      font-size: 1.6rem;
      background: #fff;
    }
  }

  .token-info {
    margin-bottom: 1rem;

    .title {
      font-weight: 600;
      font-size: 1.6rem;

      .vertical-line {
        border-left: 0.4rem solid var(--bg-color);
        height: 1.5rem;
      }

      .title-text {
        padding: 1rem;
      }
    }

    .item-box {
      padding: 1rem 1.5rem;
      font-size: 1.6rem;
      background: #fff;
    }
  }

  .self-info {
    margin-bottom: 1rem;

    .title {
      font-weight: 600;
      font-size: 1.6rem;

      .vertical-line {
        border-left: 0.4rem solid var(--bg-color);
        height: 1.5rem;
      }

      .title-text {
        padding: 1rem;
      }
    }

    .item-box {
      padding: 1rem 1.5rem;
      font-size: 1.6rem;
      background: #fff;
    }
  }
}
