@font-face {
  font-family: "iconfont"; /* Project id 2286649 */
  src: url("//at.alicdn.com/t/font_2286649_ptkcxq6te8.woff2?t=1640081038574")
      format("woff2"),
    url("//at.alicdn.com/t/font_2286649_ptkcxq6te8.woff?t=1640081038574")
      format("woff"),
    url("//at.alicdn.com/t/font_2286649_ptkcxq6te8.ttf?t=1640081038574")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 1.6rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e668";
}

.iconshouyintai-rili1:before {
  content: "\e6d8";
}

.iconlipinkajiezhang:before {
  content: "\e6ce";
}
.iconshouyintai-dabaodingdan:before {
  content: "\e6cd";
}

.iconshouyintai-yuekoukuan:before {
  content: "\e6cc";
}

.iconshouyintai-huiyuanjiezhang:before {
  content: "\e6cb";
}

.iconshouyintai-xianjinjiezhang:before {
  content: "\e6c9";
}

.iconshouyintai-saomajiezhang:before {
  content: "\e6ca";
}

.iconshouyintai-yitianxie:before {
  content: "\e6c8";
}

.iconshouyintai-kaitongchenggong:before {
  content: "\e6c2";
}

.iconshouyintai-huiyuanchongzhi:before {
  content: "\e6c3";
}

.iconshouyintai-kaitongshibai:before {
  content: "\e6c4";
}

.iconshouyintai-xianjinchongzhi:before {
  content: "\e6c5";
}

.iconshouyintai-saomachongzhi:before {
  content: "\e6c6";
}

.iconshouyintai-xinzenghuiyuan:before {
  content: "\e6c7";
}

.iconshouyintai-huiyuanxinxi:before {
  content: "\e6bc";
}

.iconshouyintai-dingdanxiangqing:before {
  content: "\e6bd";
}

.iconshouyintai-kaitongmingxi:before {
  content: "\e6be";
}

.iconshouyintai-weitianxie:before {
  content: "\e6bf";
}

.iconshouyintai-bencidingdanxinxi:before {
  content: "\e6c0";
}

.iconshouyintai-fukuanpingzheng:before {
  content: "\e6c1";
}

.iconshouyintai-rili:before {
  content: "\e6bb";
}

.iconshouyintai-caidan:before {
  content: "\e6ba";
}

.iconshouyintai-chongzhidingdan:before {
  content: "\e6b9";
}

.iconshouyintai-zitidingdan:before {
  content: "\e6b7";
}

.iconshouyintai-chupinpeisongdan:before {
  content: "\e6b8";
}

.iconshouyintai-huiyuan:before {
  content: "\e6b5";
}

.icona-shouyintaisousuo:before {
  content: "\e6b6";
}
