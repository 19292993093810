.btabs {
  padding: 2rem 1.6rem;
  width: 100%;
  border-radius: 0.4rem;
  background-color: #fff;
  // min-width: 20vw;
  // overflow-x: scroll;
  .item-box {
    height: 4rem;
    border-radius: 4rem;
    text-align: center;
    padding: 2rem;
    cursor: pointer;
    white-space: nowrap;
    .icon {
      font-size: 1.4rem;
      margin-right: 0.4rem; 
    }
  }
  .active-item {
    background-color: var(--bg-color);
    color: white;
  }
}
