.paylist {
  height: 100vh;
  overflow: hidden;
  header {
    height: 8rem;
    padding: 1.5rem;
    background-color: #fff;
    display: flex;

    .order-search {
      flex: 1;
      margin-right: 1rem;
      position: relative;
      .icona-shouyintaisousuo:before {
        color: #037aee;
        opacity: 0.5;
      }
      .iconshouyintai-rili1:before {
        color: #037aee;
        font-size: 1.6rem;
      }
      > span {
        font-size: 2rem;
        position: absolute;
        z-index: 1;
        right: 1rem;
        top: 50%;
        transform: translate(0, -50%);
      }
      input {
        height: 4rem;
        width: 100%;
        font-size: 1.6rem;
        text-indent: 1rem;
        color: #333333;
      }
      .semi-datepicker {
        height: 5rem;
        width: 100%;
        .semi-input-wrapper-default {
          height: 5rem;
          background: #fff;
          border: 0.1rem solid #d9d9d9;
        }
        .semi-input-suffix {
          span {
            color: #fff;
          }
        }
        .semi-input-clearbtn {
          margin-right: 2rem;
        }
      }
    }

    .member-search {
      flex: 1;
      .icona-shouyintaisousuo:before {
        color: #037aee;
        opacity: 0.5;
        font-size: 1.6rem;
      }
      .ant-input-affix-wrapper {
        height: 5rem;
        border: 0.1rem solid #d9d9d9;
        input {
          width: 100%;
          text-indent: 1rem;
          color: #333333;
          font-size: 1.6rem;
        }
      }
    }
  }

  .content-box {
    overflow-y: auto;
    .pullox {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding-bottom: 2rem;
    }
    .table-title {
      background: var(--sub-bg-color);
      color: #333330;
      font-size: 1.4rem;
      padding: 1.5rem;
      font-weight: 400;

      .item-title {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }

    .item-row {
      padding: 1.5rem;
      font-size: 1.4rem;
      font-weight: 600;
      cursor: pointer;

      .item-row-one {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }

    .active {
      background-color: var(--bg-color);
      color: white;
    }
  }
}
