.goods-card {
  width: 100%;
  height: 10rem;
  cursor: pointer;

  .goods-img {
    position: absolute;
    z-index: 2;
    top: -1rem;
    width: 12rem;
    height: 12rem;
    overflow: hidden;
    border-radius: 0.8rem;
    text-align: center;
    background-color: #fff;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .goods-info {
    text-align: center;
    z-index: 1;
    padding: 0.5rem;
    position: absolute;
    flex-direction: column;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0 0.8rem 0.8rem 0;
    > div {
      margin-left: 12rem;
    }

    .goods-title {
      position: relative;
      font-size: 1.4rem;
      margin-bottom: 0.5rem;

      div {
        width: 10rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1.6rem;
        overflow: hidden;
      }
    }
    
    .goods-stock {
      font-size: 1.2rem !important;
      color: #999;
    }

    .goods-price {
      height: 2rem;
      overflow: hidden;
      font-size: 1.6rem;
      font-weight: 600;

      span {
        font-size: 1.6rem;
      }
    }

    .goods-status {
      width: 2.8rem;
      height: 2.8rem;
      font-size: 1.4rem;
      font-weight: 400;
      z-index: 99;
      border-radius: 50%;
      margin-top: 0.5rem;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}
