.skew {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.1rem;
  box-sizing: border-box;
  padding: 0 1rem;
  position: relative;

  .bg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) skew(-12deg);
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #00d0bf;
    border: 0.1rem solid #00d0bf;
    border-radius: 0.8rem 0 0 0.8rem;
  }

  &:nth-child(2) {
    .bg {
      border-radius: 0 0.8rem 0.8rem 0;
    }
  }

  .tx {
    min-width: 6rem;
    font-size: 2.2rem;
    text-align: center;
    position: relative;
    white-space: nowrap;
    // top: 50%;
    // transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
  }
}

.skew-wrap {
  border: 0.1rem solid #333333;
  border-radius: 2rem;
  overflow: hidden;

  .skew_box {
    font-size: 2.2rem;

    .member_price {
      color: #000000;
      padding: 0 1.3rem;
    }
    .member_text {
      color: #ffdbb0;
      padding: 0.2rem 1.3rem;
      background: linear-gradient(270deg, #626262 0%, #333333 100%);
    }
  }
}
