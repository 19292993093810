.showcount {
  background-color: var(--sub-bg-color);
  text-align: right;
  .item-row {
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    .count-color {
      font-weight: 400;
    }
    &:last-child {
      font-size: 1.6rem;
      font-weight: 600;
      .price-color {
        color: #ff3200;
      }
    }
  }
}
