.order-list {
  .header-input {
    position: relative;
    height: 6rem;
    border-radius: 0.4rem;
    padding: 2rem 5.7rem;
    background-color: #fff;
    .search-logo {
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: 3rem;
      transform: translate(0, -50%);
      .icona-shouyintaisousuo:before {
        font-size: 1.4rem;
        color: #037aee;
        opacity: 0.5;
      }
    }

    .member-logo {
      cursor: pointer;
      color: #ffbf44;
      height: 4rem;
      width: 9.8rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #333333;
      border-radius: 0 1.5rem 0 1.5rem;
      margin-right: 1rem;

      .logo {
        margin-right: 0.5rem;
      }
      .vip {
        font-size: 1.4rem;
      }
    }

    Input {
      height: 6rem;
      border-radius: 0.4rem;
      padding: 2rem 5.7rem;
      color: #333333;
    }
  }

  .top-section {
    background-color: white;
    border-radius: 0.4rem;
    // min-width: 20vw;
    // overflow-x: scroll;

    .left-section {
      flex: 1;
      flex-wrap: wrap;
      border-radius: 0.4rem;
    }

    .right-section {
      justify-content: flex-end;
      margin-right: 1rem;
      padding: 0.7rem 0rem;
      display: flex;
      flex-direction: column;
      .time-box {
        height: 3.2rem;
        margin-bottom: 0.7rem;
        position: relative;
        > span {
          font-size: 1.8rem;
          position: absolute;
          z-index: 1;
          right: 1rem;
          top: 50%;
          transform: translate(0, -50%);
          color: #037aee;
        }
        .semi-datepicker {
          height: 100%;

          .semi-input-wrapper {
            height: 3.2rem;
            line-height: 3.2rem;
            background: #fff;
            border-radius: 0.4rem;
            border: 0.1rem solid #d9d9d9;
            input {
              font-size: 1.4rem;
            }
          }
          .semi-input-suffix {
            span {
              color: #fff;
            }
          }
          .semi-input-clearbtn {
            margin-right: 2rem;
          }
        }
      }
      .ant-input-affix-wrapper {
        height: 3.2rem;
        line-height: 3.2rem;
        border-radius: 0.4rem;
        padding: 0.5rem 1rem;
        color: #333333;
        input {
          font-size: 1.4rem;
        }
        .ant-input-prefix {
          color: #037aee;
          opacity: 0.5;
          .icona-shouyintaisousuo {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .cards {
    flex-wrap: wrap;
    margin-top: 1rem;

    .card-box {
      margin-right: 1.5rem;
      min-width: 24rem;
      height: 14rem;
      background-color: #fff;
      box-sizing: border-box;
      border-radius: 1rem;
      margin-bottom: calc(4% / 3);
    }

    .border {
      border: 0.2rem solid var(--bg-color);
      box-shadow: 0 0 0.8rem 0 rgba(51, 51, 51, 0.3);
    }
  }
  // .cards::after {
  //   flex: 1;
  //   margin-right: 2rem;
  //   min-width: 26rem;
  //   content: "";
  // }

  // .card-box:nth-child(3n) {
  //   margin-right: 0;
  // }

  .order-list-none {
    width: 100%;
    margin-top: 4rem;
    text-align: center;
    font-size: 1.6rem;
    color: #666;
  }
}
