.popup-box {
  position: relative;
  background-color: #fff;
  border-radius: 0.4rem;
  .close-box {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 0rem;
    top: 0rem;
  }

  .popup-close {
    width: 1.6rem;
    height: 0.2rem;
    transform: rotate(45deg);
    background-color: #333;
    text-align: center;
    right: 1rem;
    top: 2rem;
    position: absolute;
    &::after {
      content: "";
      display: block;
      width: 1.6rem;
      height: 0.2rem;
      transform: rotate(90deg);
      background-color: #393f45;
    }
  }

  footer {
    width: 100%;
    height: 5.5rem;
    bottom: 1.6rem;
    button {
      border-color: var(--bg-color);
      font-size: 1.6rem;
      float: right;
      margin-right: 2rem;
      width: 8rem;
      height: 3.5rem;
      &:nth-child(1) {
        color: #fff;
        background-color: var(--bg-color);
      }
    }
  }
}
