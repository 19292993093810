.sku {
  position: relative;
  box-sizing: border-box;
  padding: 1rem 2rem;
  // border-bottom: 0.1rem solid #dedede;

  .img {
    width: 9rem;
    height: 9rem;
    overflow: hidden;
    border-radius: 0.4rem;
    margin-right: 0.8rem;

    image {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    height: 100%;
    font-size: 1.2rem;
    color: #666;

    .select {
      margin-top: 0.8rem;
    }

    .name-box {
      font-size: 1.8rem;
      color: #333333;
      font-weight: 600;
    }
  }

  .price-box {
    // font-size: 1.8rem;
    // color: #333333;
    // font-weight: 600;
    .price {
      .new {
        font-size: 1.8rem;
        color: #333333;
        font-weight: 600;
        ._moneny {
          font-size: 1.8rem;
        }
      }

      .price-box {
        font-size: 1.8rem;
        color: #333333;
        font-weight: 600;
        .price {
          .new {
            font-size: 1.8rem;
            color: #333333;
            font-weight: 600;
            ._moneny {
              font-size: 1.8rem;
            }
          }

          .old {
            margin-left: 0.8rem;
            text-decoration: line-through;
            height: 2.1rem;
            font-size: 1.8rem;
            color: #333333;
            font-weight: 600;
            line-height: 2.1rem;
          }
        }

        .extra-price {
          margin-top: 0.5rem;

          .price-l {
            .p-item2 {
              height: 1.8rem;
              border-radius: 0.4rem;
              border: 0.1rem solid var(--bg-color);
              padding: 0 0.5rem;
              font-size: 1.8rem;
              font-weight: 600;
              color: var(--bg-color);
              margin-right: 0.5rem;
            }
          }

          .sale {
            font-size: 1.8rem;
            color: #333333;
            font-weight: 600;
            line-height: 1.8rem;
          }
        }
      }
    }

    .extra-price {
      margin-top: 0.5rem;

      .price-l {
        .p-item2 {
          height: 1.8rem;
          border-radius: 0.4rem;
          border: 0.1rem solid var(--bg-color);
          padding: 0 0.5rem;
          font-size: 1.2rem;
          font-weight: 400;
          color: var(--bg-color);
          margin-right: 0.5rem;
        }
      }

      .select_list {
        box-sizing: border-box;
        flex-wrap: wrap;
        padding-bottom: 3rem;
        border-bottom: 0.1rem solid #dedede;

        // background-color: blueviolet;
        &:nth-last-of-type(1) {
          border-bottom: none;
          padding-bottom: 0;
        }

        .item {
          margin-bottom: 2rem;
          height: 3rem;
          padding: 0 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 300ms;
          background: #ededed;
          border-radius: 0.5rem;
          margin-right: 1rem;
          font-size: 1.3rem;
          font-weight: 400;
          color: #333333;
          box-sizing: border-box;
        }

        .act_item {
          font-size: 1.3rem;
          font-weight: 600;
          color: var(--bg-color);
          background: var(--sub-bg-color);
          border-radius: 0.5rem;
          border: 0.1rem solid var(--bg-color);
        }

        .disabled {
          color: rgb(200, 200, 200);
          background-color: rgb(250, 250, 250);
        }
      }
    }
  }
}

// sku选择
.spec-box {
  .select_title {
    // background-color: blue;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: #333333;
  }

  .select_list {
    box-sizing: border-box;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
    border-bottom: 0.1rem solid #dedede;

    // background-color: blueviolet;
    &:nth-last-of-type(1) {
      border-bottom: none;
      padding-bottom: 0;
    }

    .item {
      cursor: pointer;
      margin-bottom: 2rem;
      height: 3rem;
      padding: 0.5rem 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 300ms;
      background: var(--sub-bg-color);
      border-radius: 0.5rem;
      margin-right: 1rem;
      font-size: 1.6rem;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;
    }

    .act_item {
      font-size: 1.6rem;
      font-weight: 600;
      color: white;
      background: var(--bg-color);
      border-radius: 0.5rem;
      border: 0.1rem solid var(--bg-color);
    }

    .disabled {
      color: rgb(200, 200, 200);
      background-color: rgb(250, 250, 250);
    }
  }
}

// 数量选择
.buy_number {
  // border-top: 0.1rem solid #dedede;
  height: 2.8rem;
  padding-bottom: 1.7rem 0;

  .number_title {
    color: #333;
    > div {
      font-size: 1.4rem;
    }

    .stock {
      font-size: 1.2rem;
      margin-left: 1rem;
      color: #999;
    }
  }

  .handle_input {
    width: 12rem;
    height: 3.5rem;

    .btn {
      width: 4rem;
      font-size: 1.4rem;
    }

    .no {
      width: 4rem;
      font-size: 1.4rem;
    }

    .add {
      width: 4rem;
      font-size: 1.4rem;
    }

    .num {
      height: 3.5rem;
      line-height: 3.5rem;
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
}

.btn_wrap {
  display: flex;
  color: #fff;
  font-size: 1.4rem;
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 2rem;

  .btn {
    font-size: 1.6rem;
    flex: 1;
    height: 4.5rem;
    margin: 0 auto;
    background-color: rgb(235, 55, 43);
  }

  .normal {
    border-radius: 0.4rem !important;
  }

  .buy-btn {
    border-radius: 0 2rem 2rem 0;
  }

  .cart-btn {
    background-color: var(--bg-color);
    border-radius: 2rem 0 0 2rem;
  }
}
