.proTime-dialog {
  margin-bottom: 2rem;
  .title {
    font-size: 1.4rem;
    font-weight: 400;
    color: #333333;
    margin: 1rem 0 0.5rem;
  }
  .proTime {
    width: 100%;
    position: relative;
    .iconshouyintai-rili1:before {
      color: #037aee;
    }
    > span {
      font-size: 1.4rem;
      position: absolute;
      z-index: 1;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
    }
    .semi-datepicker {
      width: 100%;
      height: 4rem;
      .semi-input-wrapper {
        height: 4rem;
        line-height: 4rem;
        border-radius: 0.4rem;
        border: 0.1rem solid #ccc;
        input {
          height: 4rem;
          font-size: 1.4rem;
          color: #333333;
        }
      }

      .semi-input-wrapper-default {
        height: unset;
        line-height: unset;
      }
      .semi-input-wrapper-focus {
        border: 0.1rem solid var(--bg-color);
      }

      div {
        background: #fff;
        height: 100%;
      }
      .semi-input-suffix {
        span {
          color: #fff;
        }
      }
      .semi-input-clearbtn {
        margin-right: 2rem;
      }
    }
  }
}
