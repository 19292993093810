.orderList {
  height: 100%;
  header {
    height: 8rem;
    .left-box {
      width: 60%;
      height: 100%;
      padding: 1.5rem;
      .header-title {
        font-size: 1.8rem;
        font-weight: 500;
        .purple {
          color: var(--purple-color);
        }
      }
    }
    .right-box {
      height: 100%;
      margin: 1rem;
      .warning-info {
        background-color: #fd7e24;
        border-radius: 2.3rem;
        padding: 1rem;
        font-size: 1.4rem;
        color: white;
        align-items: center;
        justify-content: center;
        height: 3.5rem;
        cursor: pointer;
        .logo {
          font-size: 1.4rem;
          margin-right: 0.5rem;
        }
      }
      .green {
        background-color: #46cb81;
      }
    }
  }
  .content-box {
    padding-bottom: 8.7rem;
    .detail-box {
      background-color: #f4f6f8;
    }
  }
  footer {
    background: #999;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
