.paycard {
  border-radius: .4rem;
  width: 100%;
  background-color: #fff;
  .title-box {
    border-bottom: .2rem solid rgba(51, 51, 51, 0.08);
    font-size: 1.6rem;
    padding: 1.5rem;
    .row-one {
      font-weight: 600;
      .icon {
        font-size: 1.4rem;
        color: var(--bg-color);
        margin-right: .4rem;
      }
    }
    .total-one {
      font-weight: 600;
      margin-right: 1rem;
      .color-red {
        color: #FF3200;
      }
    }
    .total-two {
      font-weight: 600;
      .color-red {
        color: #FF3200;
      }
    }
  }
  .item-row {
  align-items: center;

  .title-key {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .title-value {
    font-size: 1.4rem;
    font-weight: 600;
  }
}
}