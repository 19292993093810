.newMemberInfo {
  .new-Member {
    padding: 2rem 2rem 0;
    // height: 27.3rem;
    background: #ffffff;
    box-shadow: 0 0 0.8rem 0 rgba(51, 51, 51, 0.2);
    border-radius: 0.4rem;
    .icon {
      float: left;
      width: 2.4rem;
      height: 2.4rem;
      color: var(--bg-color);
      margin-right: 0.4rem;
    }

    .title {
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 600;
      color: #333333;
    }

    .content {
      font-size: 1.4rem;
      margin-top: 2rem;
      > div {
        margin-bottom: 1.5rem;
        display: block;
        > span {
          float: right;
          font-size: 1.4rem;
          font-weight: 600;
          color: #333333;
        }
        .balance-not-enough {
          float: right;
          margin-right: 1.5rem;
          color: #fd7e24;
        }
      }
      .recharge {
        border-top: 0.1rem solid rgba(51, 51, 51, 0.08);
        height: 7rem;
        color: #101010;
        font-size: 1.6rem;
        span {
          display: block;
          margin-right: 2.3rem;
        }
        .semi-input-wrapper {
          flex: 1;
          background: #fff;
          font-size: 1.4rem;
          border-radius: 0.4rem;
          border: 0.1rem solid #a8bcd0;

          input {
            width: 100%;
            height: 4rem;
            font-size: 1.4rem;
            color: #333333;
          }
        }
        .semi-input-wrapper-default {
          height: unset;
          line-height: unset;
        }
        .semi-input-wrapper-focus {
          border: 0.1rem solid var(--bg-color);
        }
      }
      .row-two {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        padding-bottom: 2rem;

        .gift-amount {
          span {
            font-size: 1.6rem;
            font-weight: 600;
            color: #333333;
          }
        }
      }
    }
  }

  .payMethod {
    margin-top: 2.5rem;
    justify-content: flex-end;
    .type {
      font-size: 1.4rem;
    }
    > div {
      display: flex;
      width: 13rem;
      height: 3.5rem;
      background: var(--bg-color);
      border-radius: 0.4rem;
      color: #fff;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        float: left;
        color: #fff;
        margin-right: 0.4rem;
      }
      .title {
        display: inline-block;
      }

      margin-left: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
