.semi-modal-body-wrapper {
  margin: 2rem 0;
  button {
    margin-right: 1rem;
    margin-top: -1rem;
  }
}
.second-sure {
  .title {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #333333;
  }
  .content {
    font-size: 1.8rem;
    color: #444;
  }
}
