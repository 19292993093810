.giftCartPay {
  .title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333333;
    padding: 2rem 2rem 3rem;
    .icon {
      font-size: 1.4rem;
      color: var(--bg-color);
      margin-right: 0.4rem;
    }
  }
  .giftCard,
  .useMember {
    align-items: center;
    height: 8rem;
    .icona-shouyintaisousuo:before {
      margin: 0 0.5rem 0 1rem;
      color: #037aee;
      opacity: 0.5;
      font-size: 1.6rem;
    }
    .inputNumber {
      font-size: 1.4rem;
      font-weight: 400;
      color: #333333;
    }
    .semi-input-wrapper {
      margin: 0 2rem 0 2rem;
      flex: 1;
      // height: 4rem;
      // line-height: 4rem;
      background: #ffffff;
      border-radius: 0.4rem;
      border: 0.1rem solid #a8bcd0;
      input {
        font-size: 1.4rem;
        height: 4rem;
        color: var(--bg-color);
      }
    }
    .search {
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
      color: var(--bg-color);
      font-size: 1.4rem;
    }
  }
  .useMember {
    .inputNumber {
      margin-right: 1rem;
    }
    .semi-input-wrapper {
      border-radius: 0.4rem;
      border: 0.1rem solid #a8bcd0;
      flex: 1;
      input {
        height: 4rem;
        font-size: 1.4rem;
        color: var(--bg-color);
      }
    }
  }
  .actualAmount {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333333;
    height: 2.2rem;
    margin-top: 1.5rem;
    > span {
      float: right;
      span {
        color: #ff3200;
      }
    }
  }

  .payMethod {
    margin-top: 2.5rem;
    justify-content: flex-end;
    .type {
      font-size: 1.4rem;
    }
    > div {
      display: flex;
      width: 13rem;
      height: 3.5rem;
      background: var(--bg-color);
      border-radius: 0.4rem;
      color: #fff;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .icon {
        float: left;
        font-size: 1.6rem;
        color: #fff;
        margin-right: 0.4rem;
      }

      margin-left: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.memberPay {
  padding-bottom: 2rem;
}
