.goods-list {
  .header-input {
    position: relative;
    height: 6rem;
    border-radius: 0.4rem;
    padding: 2rem 5.7rem;
    border-radius: 0 0 0.4rem 0.4rem;
    background-color: #fff;
    .search-logo {
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: 3rem;
      transform: translate(0, -50%);
      .icona-shouyintaisousuo:before {
        color: #037aee;
        opacity: 0.5;
        font-size: 1.4rem;
      }
    }
    .member-logo {
      cursor: pointer;
      color: #ffbf44;
      height: 4rem;
      width: 9.8rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #333333;
      border-radius: 0 1.5rem 0 1.5rem;
      margin-right: 1rem;

      .logo {
        margin-right: 0.5rem;
      }
      .vip {
        font-size: 1.4rem;
      }
    }
  }

  .tab-box {
    background-color: #fff;
    // min-width: 20vw;
    // overflow-x: scroll;
    .search-box {
      padding: 1.5rem 1rem;
      .ant-input-affix-wrapper {
        height: 3.2rem;
        line-height: 3.2rem;
        width: 20rem;
        border-radius: 0.4rem;
        padding: 0.5rem 1rem;
        color: #333333;
        input {
          font-size: 1.4rem;
        }
        .ant-input-prefix {
          color: #037aee;
          opacity: 0.5;
          .icona-shouyintaisousuo {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .cards {
    flex-wrap: wrap;
    margin-top: 1rem;

    .goodCard {
      margin-right: 2rem;
      min-width: 26rem;
      height: 10rem;
      background-color: #fff;
      border-radius: 0.8rem;
      box-sizing: border-box;
      margin-top: 1rem;
      margin-bottom: 2rem;
      position: relative;
    }
  }

  // .cards::after {
  //   flex: 1;
  //   margin-right: 2rem;
  //   //  min-width: 26rem;
  //   content: "";
  // }

  .goods-card:nth-child(3n) {
    margin-right: 0;
  }
}

.sku-box {
  background-color: #fff;
  border-radius: 0.4rem;
  position: relative;
  border-radius: 0.4rem;
  width: 43rem;
  .sku-header {
    height: 5.5rem;
    border-bottom: 0.1rem solid #dedede;
    padding: 1.5rem 2rem;
    .pro-title {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }

  .close {
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
  .popup-close {
    font-weight: 600;
    font-size: 2.4rem;
  }
}
