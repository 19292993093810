.loading-dialog {
  padding: 3rem;
  justify-content: flex-start;
  .loadingIcon {
    align-items: center;
    width: 4.5rem;
    height: 4.5rem;
    flex-wrap: wrap;
    margin-right: 1.5rem;
    > div {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
    }
    .load1 {
      background: rgba(3, 122, 238, 0.15);
    }
    .load2 {
      background: rgba(3, 122, 238, 0.4);
    }
    .load3 {
      background: rgba(3, 122, 238, 0.9);
    }
    .load4 {
      background: rgba(3, 122, 238, 0.65);
    }
  }
  .loading-title {
    font-size: 1.6rem;
  }
  .loading-content {
    margin-top: 0.6rem;
    font-size: 1.4rem;
    color: #666;
  }
}
