.paymethod {
  background-color: var(--bg-color);
  color: white;
  padding: 1rem;
  .pay-item {
    cursor: pointer;
  }
  .icon-box {
    font-size: 2.4rem !important;
    margin-bottom: 1rem;
  }
}
