.pick-info {
  .mustInput {
    color: #f50000;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 400;
    color: #333333;
    margin: 1rem 0 0.5rem;
  }
  .pick-time {
    // height: 4rem;
    border-radius: 0.4rem;
    width: 100%;
    position: relative;
    .iconshouyintai-rili1:before {
      color: #037aee;
    }
    > span {
      position: absolute;
      font-size: 1.4rem;
      z-index: 1;
      right: 1rem;
      top: 50%;
      transform: translate(0, -50%);
    }
    .semi-datepicker {
      width: 100%;
      height: 4rem;
      line-height: 4rem;
      border-radius: 0.4rem;
      input {
        line-height: 4rem;
        font-size: 1.4rem;
      }
      .semi-input-wrapper-default {
        border: 0.1rem solid #d9d9d9;
      }
      div {
        height: 100%;
        background: #fff;
      }
      .semi-input-suffix {
        span {
          color: #fff;
        }
      }
      .semi-input-clearbtn {
        margin-right: 2rem;
      }
    }
  }
  .customer-phone {
    .semi-input-wrapper {
      background: #fff;
      border-radius: 0.4rem;
      border: 0.1rem solid #a8bcd0;

      input {
        width: 100%;
        height: 4rem;

        font-size: 1.4rem;
        color: #333333;
      }
    }
    .semi-input-wrapper-default {
      height: unset;
      line-height: unset;
    }
    .semi-input-wrapper-focus {
      border: 0.1rem solid var(--bg-color);
    }
  }

  .goods-remark {
    margin-bottom: 2rem;
    .semi-input-wrapper {
      background: #fff;
      border-radius: 0.4rem;
      border: 0.1rem solid #a8bcd0;
      input {
        width: 100%;
        height: 4rem;
        font-size: 1.4rem;
        color: #333333;
      }
    }
    .semi-input-wrapper-default {
      height: unset;
      line-height: unset;
    }
    .semi-input-wrapper-focus {
      border: 0.1rem solid var(--bg-color);
    }
  }
}
