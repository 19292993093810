.cashPay-dialog {
  .content {
    > div {
      margin-bottom: 4rem;
      font-size: 2rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #333333;

      & > span {
        display: inline-block;
        font-size: 1.6rem;
        font-weight: 600;
        color: #ff3200;
        margin-left: 2rem;

        .za-input--focus {
          padding: unset;
        }
      }
    }

    .received {
      margin-top: 3rem;
    }

    .actual {
      justify-content: flex-start;

      > span {
        > div {
          padding-right: 0;
        }
      }
      .semi-input-wrapper {
        width: 30rem;
        background: #ffffff;
        border-radius: 0.4rem;
        border: 0.1rem solid #a8bcd0;
        input {
          width: 100%;
          height: 4rem;
          font-size: 1.6rem;
          color: #333333;
        }
      }
      .semi-input-wrapper-default {
        height: unset;
        line-height: unset;
      }
      .semi-input-wrapper-focus {
        border: 0.1rem solid var(--bg-color);
      }
    }
  }
}
