.sumPage {
  background-color: #F4F6F8;

  .sum-section {
    text-align: unset !important;
    margin-bottom: 1rem;
    background-color: white;

    .title {
      margin-right: 4rem;
    }

    .order-type {
      font-weight: 600;
      display: flex;
      align-items: center;
      font-size: 2.2rem !important;
      height: 6.5rem;
      padding: 1.7rem 1rem;
      background-color: white;
    }

    .order-type-1 {
      background-color: white;
      padding: 1rem 1.5rem;
    }

    .order-type-0 {
      padding: 1rem 1.5rem;
      background-color: white;

      .order-type-2 {
        background-color: white;

        .first-type {
          padding-bottom: 1rem;
          .title {
            font-weight: 600;
          }
          .value {
            font-weight: 600;
            .text-red {
              font-weight: 600;
              color: rgba(255, 50, 0, 1);
            }
          }
        }

        .order-type-3 {
          color: #666;
          padding-bottom: 1rem;
          margin-left: 1rem;
        }
      }

      .line-row {
        padding: 0 1.5rem;
        border-bottom: 0.1rem solid #ccc;
      }
    }
    &:last-child {
      margin-bottom: unset;
    }
  }
}