.proItem {
  padding: 2rem 1.6rem 1.5rem;
  border-bottom: 0.1rem solid #ccc;
  .proitem-box {
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;

    &.money {
      text-align: right;
      .member-price {
        text-decoration: line-through;
      }
    }
  }
  .clear {
    font-size: 1.4rem;
    font-weight: 600;
    color: #037aee;
    text-decoration: underline;
    margin-top: 1.5rem;
  }
}
