.handle_input {
  display: flex;
  height: 3rem;
  width: 8rem;
  overflow: hidden;
  background-color: var(--sub-bg-color);
  border-radius: 0.5rem;

  .num {
    width: 4rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.4rem;
    background-color: rgb(238, 240, 240);
    text-align: center;
    background-color: #ededed;
    border: none;
    cursor: pointer;
    background-color: var(--sub-bg-color);
    overflow: hidden;

    &::placeholder {
      color: #333;
    }
  }

  .btn {
    cursor: pointer;
    text-align: center;
    width: 3rem;
    // line-height: 3rem;
    height: 100%;
    background-color: #ededed;
    font-size: 1rem;
    border-radius: 0.5rem 0 0 0.5rem;
    color: var(--bg-color);
    border: none;
    background-color: var(--sub-bg-color);

    &:nth-of-type(2) {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }

  .no {
    background: #f3f3f3 !important;
    color: #adadad !important;
  }
}
.orderNum {
  width: 100%;
  text-align: center;
}
