.ScanningPay {
  padding-bottom: 2rem;

  .title {
    height: 5.5rem;
    line-height: 5.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    border-bottom: 0.1rem solid rgba(51, 51, 51, 0.08);
  }
  .orderAmount {
    height: 5.5rem;
    line-height: 5.5rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: #ff3200;
  }

  .payType {
    width: 100%;
    margin-top: 2rem;
    font-size: 2rem;
    margin-bottom: 2rem;

    .current {
      border: 0.1rem solid var(--bg-color);
      border-radius: 0.4rem;
      color: white !important;
      font-weight: 400;
      align-items: center;
      background-color: var(--bg-color);
    }

    .mb-2 {
      margin-bottom: 2rem;
      font-size: 1.6rem;
    }

    .aliPay {
      height: 25rem;
      width: 20rem;
      flex-direction: column;
      border: 0.1rem solid var(--bg-color);
      border-radius: 0.4rem;
      color: var(--bg-color);
      font-weight: 600;
    }

    .weChatPay {
      height: 25rem;
      width: 20rem;
      flex-direction: column;
      border: 0.1rem solid var(--bg-color);
      border-radius: 0.4rem;
      color: var(--bg-color);
      font-weight: 600;
    }

    .boxPay {
      height: 25rem;
      width: 20rem;
      flex-direction: column;
      color: var(--bg-color);
      border: 0.1rem solid var(--bg-color);
      border-radius: 0.4rem;
      font-weight: 600;
    }
  }
  .tips {
    font-size: 2rem;
    font-weight: 600;
    color: #ff3200;
  }
  .icon {
    font-size: 2rem;
    color: #ff3200;
    margin-right: 1rem;
  }
}
