.App {
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 1.4rem;
  flex-direction: column;
  font-family: PingFangSC-Regular, PingFang SC;
  user-select: none;

  header {
    width: 100%;
  }

  .contain {
    height: 100%;
    flex: 1;
  }
}

.semi-modal {
  .semi-modal-content {
    border: none;
    padding: 0;

    .semi-modal-header {
      margin: 0;
      height: 5.5rem;
      line-height: 5.5rem;

      border-bottom: 0.1rem solid rgba(51, 51, 51, 0.08);
      padding: 0 2rem;
      z-index: -1;
      h5 {
        font-size: 1.8rem;
        font-weight: 600;
        color: #333333;
      }

      .semi-modal-title {
        align-items: center;
        line-height: 5.5rem;
      }
      .semi-button {
        width: 4rem;
        height: 4rem;
      }
    }
  }

  .semi-modal-body {
    padding: 0 2rem;
  }

  .semi-modal-footer {
    padding: 0 2rem;
    margin: 0;
    span {
      font-size: 1.4rem;
      padding: 0 1rem;
    }

    .semi-button {
      height: 3.5rem;

      margin-bottom: 2rem;
      border: 0.1rem solid var(--bg-color);
      &:first-child {
        background-color: #fff;
      }
    }
  }
}
.semi-datepicker {
  .semi-button-content {
    span {
      font-size: 1.6rem;
    }
  }
  .semi-datepicker-weekday-item {
    font-size: 1.6rem;
  }
  .semi-datepicker-day-main {
    span {
      font-size: 1.4rem;
    }
  }
}

.semi-spin-wrapper {
  color: var(--bg-color);
}

.ant-select-item-option-content {
  font-size: 1.4rem;
  color: #333333;
}
.ant-message {
  .anticon {
    font-size: 2.2rem;
  }
  span {
    font-size: 1.6rem;
  }
}
