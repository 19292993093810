.member-manage {
  .title {
    height: 5.5rem;
    line-height: 5.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    border-bottom: 0.1rem solid rgba(51, 51, 51, 0.08);
    padding: 0 2rem;
  }
  .content {
    height: 4rem;
    text-align: center;
    height: 12.5rem;
    align-items: flex-start;
    cursor: pointer;
    > div {
      margin-top: 3rem;
      width: 16rem;
      height: 3.5rem;
      border-right: 0.2rem solid rgba(51, 51, 51, 0.08);
      &:last-child {
        margin-right: 0;
        border-right: none;
      }
      .logo {
        width: 4.8rem;
        height: 4.8rem;
        font-size: 1.6rem;
        margin: -1rem auto;
        background-color: #ffbf44;
        border-radius: 50%;
      }
      > span {
        display: block;
        font-size: 1.6rem;
        font-weight: 400;
        color: #333333;
        margin-top: 2rem;
      }
    }
  }
}
