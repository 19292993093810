.order-card {
  width: 100%;
  height: 100%;
  // border-radius: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  img {
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .order-title {
    padding: 1.5rem 1.5rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    .order-number {
      display: block;
      height: 2.8rem;
      font-size: 2rem;
      font-weight: 600;
      color: #333333;
      line-height: 2.8rem;
    }

    .order-status {
      text-align: left;
      flex-direction: column;
      span {
        display: block;
        text-align: right;
        // width: 8rem;

        height: 2.2rem;
        font-size: 1.6rem;
        font-weight: 400;
        color: #333333;
        justify-content: flex-end;
      }

      .order-type {
        margin-bottom: 1rem;
        max-width: 7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .order-gift {
    position: relative;
    z-index: 2;
    margin-top: 1rem;
    float: left;
    padding: 0 0.6rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: #666666;
    background: #f5d6ff;
    border-radius: 1rem;
  }
  .payMethod {
    float: left;
    height: 2rem;
    line-height: 2rem;
    min-width: 4rem;
    margin-right: 0.5rem;
    border-radius: 1rem;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: #666666;
  }

  .order-info {
    padding: 0 1.5rem 1rem;
    font-size: 1.6rem;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;

    .order-state {
      display: inline-block;
      font-weight: 600;
      font-size: 1.6rem;
    }
    .preTime {
      background: var(--bg-color);
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 1rem;
      border-radius: 0.4rem;
      color: #fff;
      font-size: 1.4rem;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
    }

    .order-operation {
      padding: 0.2rem 1rem;
      line-height: 2.5rem;
      background: var(--bg-color);
      border-radius: 0.4rem;
      color: #fff;
      font-size: 1.4rem;
      font-weight: 600;
      cursor: pointer;
    }
    .gitCard {
      flex-direction: column;
    }

    .amount {
      flex-direction: column;
      .order-amount {
        font-weight: 600;
        font-size: 1.6rem;
      }
      .order-handle {
        padding: 0.2rem 1rem;
        line-height: 2.5rem;
        background: var(--bg-color);
        border-radius: 0.4rem;
        color: #fff;
        font-size: 1.4rem;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

// .order-card::after {
//   width: 24%;
//   content: '';
// }

// .border {
//   border: 0.2rem solid var(--bg-color);
//   box-shadow: 0 0 0.8rem 0 rgba(51, 51, 51, 0.3);
//   box-sizing: border-box;
// }

.order-card + :nth-child(4n) {
  margin-right: unset;
}
