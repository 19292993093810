.right-pages {
  height: 100%;
  position: relative;
  background-color:  #F4F6F8;
  .content-box {
    padding: 1rem;
  }
  footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 1rem;
  }
}