.detailCard {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;

  .item {
    width: 49%;
    height: 26.5rem;
    border-radius: 0.4rem;
    background-color: #fff;
    margin-bottom: 1.5rem;
    flex: 0 0 49%;
    flex-wrap: wrap;

    .title-row {
      font-weight: 600;
      padding: 1.5rem;
      font-size: 1.6rem;
      border-bottom: 0.2rem solid rgba(51, 51, 51, 0.08);
      .pay-msg {
        width: 8rem;
        // height: 3rem;
        background-color: rgba(70, 203, 129, 0.15);
        align-items: center;
        border-radius: 1.8rem;
        font-size: 1.4rem;
        color: rgba(70, 203, 129, 1);
        display: flex;
        justify-content: center;
      }
      .red-msg {
        width: 8rem;
        // height: 3rem;
        background-color: rgba(255, 50, 0, 0.15);
        align-items: center;
        border-radius: 1.8rem;
        font-size: 1.4rem;
        color: rgba(255, 50, 0, 1);
        display: flex;
        justify-content: center;
      }
      .warn-msg {
        width: 8rem;
        // height: 3rem;
        background-color: rgba(253, 126, 36, 0.15);
        align-items: center;
        border-radius: 1.8rem;
        font-size: 1.4rem;
        color: rgba(253, 126, 36, 1);
        display: flex;
        justify-content: center;
      }
    }

    .icon {
      font-size: 1.4rem;
      color: var(--bg-color);
      margin-right: 0.4rem;
    }

    .item-row {
      background: #fff;
      margin: 1.5rem 0;
      padding: 0 1.5rem;
      font-size: 1.4rem;
      .value {
        font-weight: 600;
      }
    }
  }
}
