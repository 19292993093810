.loginindex {
  height: 100%;
  width: 100%;

  .left-box {
    width: 40%;

    .image-box {
      margin: 0 auto 2rem;
      width: 16%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .project-name {
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
      color: #333333;
      margin-bottom: 3rem;
    }

    .operate-box {
      padding: 0 5rem;

      input {
        margin: 0 auto 2rem;
        width: 100%;
        height: 4rem;
        border-radius: 0.4rem;
        border: 0.1rem solid #a8bcd0;
        display: flex;
        text-align: center;
        color: #333333;
        font-size: 1.4rem;
      }

      .select-box {
        width: 100%;
        padding-bottom: 2rem;
        margin: 0 auto;

        .ant-select {
          width: 100%;

          .ant-select-selector {
            height: 4rem;
            width: 100%;
            // width: 32vw;
            border-radius: 0.4rem;
            border: 0.1rem solid #a8bcd0;
            display: flex;
            text-align: center;
            align-items: center;
            input {
              font-size: 1.4rem;
            }
          }
        }
      }

      .root {
        margin-bottom: 3rem;
      }

      button {
        margin: auto;
        width: 100%;
        // width: 32vw;
        height: 4rem;
        background: #037aee;
        border-radius: 0.4rem;
        span {
          font-size: 1.6rem;
        }
      }
    }
  }

  .right-box {
    width: 60%;
    height: 100%;
    background: #037aee;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .za-button--primary {
    border: unset;
  }
}
