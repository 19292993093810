.orderInfo {
  background: #ffffff;
  box-shadow: 0 0 0.8rem 0 rgba(51, 51, 51, 0.2);
  border-radius: 0.4rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  .content {
    > div {
      padding: 0 2rem;
      font-size: 1.4rem;
      color: #333333;
      > span {
        float: right;
        font-size: 1.4rem;
        font-weight: 600;
        color: #333333;
      }
    }
    .gitCardNo {
      margin-bottom: 1.5rem;
    }
    .orderAmount {
      margin-bottom: 1.5rem;
    }
    .preferAmount {
      margin-bottom: 1.5rem;
    }
    .coupon {
      span {
        font-size: 1.4rem;
        font-weight: 600;
        color: #333333;
      }
    }
    .needPay {
      font-size: 1.6rem;
      font-weight: 600;
      margin-top: 2rem;
      span {
        font-weight: 600;
        font-size: 1.6rem;
        color: #ff3200;
      }
    }
  }
}
