.order-tabs {
  > div {
    padding-top: 1rem;
  }
  padding: 1.5rem 1rem;
  width: 100%;
  background-color: #fff;
  .item-box {
    margin-right: 1rem;
    padding: 0.2rem 1.1rem;
    border-radius: 0.4rem;
    cursor: pointer;
    white-space: nowrap;
    > div {
      font-size: 1.4rem;
    }
  }
  .active-item {
    background-color: var(--bg-color);
    color: white;
  }
}
