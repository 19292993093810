.makeOrder {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .logo-image {
    width: 6vw;
    color: white;
    margin-bottom: 1.5rem;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .text-tips {
    margin-bottom: 5.7rem;
    font-size: 1.6rem;
  }

  .text-margin {
    margin: 1.5rem 0;
  }

  .text-margin-bt {
    margin-bottom: 3rem;
  }

  .btn-row {
    margin: 2.5rem 0;
  }

  .active-btn {
    background-color: var(--bg-color) !important;
    color: #fff;
  }

  Button {
    width: 22rem;
    height: 3.5rem;
    background: rgba(3, 122, 238, 0.05);
    border-radius: 0.4rem;
    border: 0.1rem solid var(--bg-color);
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--bg-color);
  }

  .sum-section {
    width: 100%;
    height: 100%;
    background-color:  #F4F6F8;
    .title {
      margin-right: 4rem;
    }
    .order-type {
      font-weight: 600;
      font-size: 2.2rem;
      height: 6.5rem;
      padding: 1.7rem 1rem;
      background-color: white;
    }
  }
}
