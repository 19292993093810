.giftInfo {
  background: #ffffff;
  box-shadow: 0 0 0.8rem 0 rgba(51, 51, 51, 0.2);
  border-radius: 0.4rem;
  margin-bottom: 1rem;

  .content {
    font-size: 1.4rem;
    padding-bottom: 1.5rem;

    > div {
      padding: 0 2rem;
      margin-bottom: 1.5rem;
      display: block;

      > span {
        float: right;
        font-size: 1.4rem;
        font-weight: 600;
        color: #333333;
      }

      /* .balance-not-enough {
        float: right;
        margin-right: 1.5rem;
        color: #fd7e24;
      } */
    }

    .memberName,.memberBalance {
      display: flex;
      justify-content: space-between;
      span{
        font-weight: bold;
      }
    }

    .memberBalance:not(.memberBalancc:last-child ) {
      margin-bottom: 3.5rem!important;
    }

    .balance-not-enough {
      display: flex;
      justify-content: flex-end;
      color: #ff3200;
      text-decoration: underline;
      cursor: pointer;
    }

    .giftCard {
      align-items: center;
      display: flex;
      height: auto!important;

      .icona-shouyintaisousuo:before {
        margin: 0 0.5rem 0 1rem;
        color: #037aee;
        opacity: 0.5;
        font-size: 1.6rem;
      }

      .inputNumber {
        font-size: 1.4rem;
        font-weight: 400;
        color: #333333;
        width: 60%;
      }

      .semi-input-wrapper {
        margin: 0 2rem 0 2rem;
        flex: 1;
        // height: 4rem;
        // line-height: 4rem;
        background: #ffffff;
        border-radius: 0.4rem;
        border: 0.1rem solid #a8bcd0;

        input {
          font-size: 1.4rem;
          height: 4rem;
          color: var(--bg-color);
        }
      }

      .search {
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
        color: var(--bg-color);
        font-size: 1.4rem;
      }
    }

    .memberName {
      margin-bottom: 1.5rem;
    }

    .memberBalance {
      margin-bottom: 0;
    }

    .memberBalance {
      margin-bottom: 1.5rem;
    }
  }
}
