.memberInfo {
  background: #ffffff;
  box-shadow: 0 0 0.8rem 0 rgba(51, 51, 51, 0.2);
  border-radius: 0.4rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  .content {
    font-size: 1.4rem;
    > div {
      padding: 0 2rem;
      margin-bottom: 1.5rem;
      display: block;
      > span {
        float: right;
        font-size: 1.4rem;
        font-weight: 600;
        color: #333333;
      }
      .balance-not-enough {
        float: right;
        margin-right: 1.5rem;
        color: #fd7e24;
      }
    }
  }
  .recharge {
    border-top: 0.1rem solid rgba(51, 51, 51, 0.08);
    color: #101010;
    font-size: 1.6rem;
    padding: 0 2rem;
    .row-one {
      height: 7rem;
      span {
        display: block;
        margin-right: 2.3rem;
      }
      .semi-input-wrapper {
        flex: 1;
        background: #fff;
        font-size: 1.4rem;
        border-radius: 0.4rem;
        border: 0.1rem solid #a8bcd0;
        input {
          width: 100%;
          height: 4rem;
          color: #333333;
          font-size: 1.4rem;
        }
      }
      .semi-input-wrapper-default {
        height: unset;
      }
    }
    .row-two {
      display: flex;
      justify-content: flex-end;
      .gift-amount {
        span {
          font-size: 1.6rem;
          font-weight: 600;
          color: #333333;
        }
      }
    }
  }
}
