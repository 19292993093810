.member-add-tips {
  > .title {
    height: 5.5rem;
    line-height: 5.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333333;
    border-bottom: 0.1rem solid rgba(51, 51, 51, 0.08);
    padding: 0 2rem;
  }
  > .content {
    padding: 0 2rem 2rem;
    .success,
    .fair {
      padding-top: 1.2rem;
      height: 16.5rem;
      text-align: center;
      margin-bottom: 1.5rem;
      .logo {
        width: 9.4rem;
        height: 9.4rem;
        font-size: 9.4rem;
        margin: -2rem auto 3rem;
      }
      span {
        display: block;
        font-size: 1.8rem;
        font-weight: 600;
        color: #333333;
      }
      .problem {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
    .success {
      .logo {
        color: #46cb81;
      }
    }
    .fair {
      .logo {
        color: #ff3200;
      }
    }
    .return {
      width: 27rem;
      height: 3.5rem;
      margin: 0 auto;
      background: var(--bg-color);
      border-radius: 0.4rem;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.4rem;
    }
  }
}
