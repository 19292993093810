.orderNotes {
  background: #fff;
  margin-bottom: 1rem;

  .title {
    font-weight: 600;
    font-size: 1.6rem;

    .vertical-line {
      border-left: 0.4rem solid var(--bg-color);
      height: 1.5rem;
    }

    .title-text {
      padding: 1rem;
    }
  }
  .notePadding {
    padding: 1rem 1.5rem;
    font-size: 1.6rem;
  }

  .borderTop {
    margin: 0 1.5rem;
    border-top: 0.1rem dashed #ccc;
  }

  .item-box {
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    background: #fff;
  }
  .actualPay {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333;
  }
  .price-color {
    font-size: 1.6rem;
    font-weight: 600;
    color: #ff3200;
  }
  .refund-msg {
    text-align: right;
    color: #ff3200;
    padding: 0rem 1.5rem 1rem;
  }
}
